export default {
    menuList:[
        { name: '法律援助', routeName: 'lawFirmLegalAid',iconType:'svg',iconSrc:'/img/icons-svg/menu/handling.svg',},
    ],
    displayRules:{
        /*
          *level-按Admin账号等级显示;
          *specific-指定AdminID显示;
          *specificAll-指定AdminID及下属全部显示;
          *specificHidden-指定AdminID隐藏;
          *specificHideAll-指定AdminID及下属全部隐藏;
          *默认全部展示
          *需要建立与routeName相同名称的对象
          *填入ID，对应规则生效
          *按层级判断，有一个判断结果为真则显示
        */

    }
}
